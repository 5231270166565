import React, { useMemo } from 'react';

import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';

import { Nullable } from 'common/utils/assert';
import { Conversation } from 'containers/Chat/Chat.types';
import { ConversationList } from 'containers/Chat/Conversation/ConversationList';

import { NoteItemMarkdown } from './NoteItemMarkdown/NoteItemMarkdown';
import { ShortConversationList } from './ShortConversationList/ShortConversationList';

export interface NoteItemContentProps extends BoxProps {
  content: string;
  conversation: Nullable<Conversation>;
  editable?: boolean;
  highlight?: string;
  initials: string | undefined;
  isChatConversation: Nullable<boolean>;
  isShortConversation?: boolean;
}

export const NoteItemContent = ({
  content,
  conversation,
  editable,
  highlight,
  initials,
  isChatConversation,
  isShortConversation,
  ...props
}: NoteItemContentProps) => {
  const contentComponent = useMemo(() => {
    if (!conversation || !conversation.messages.length) {
      return (
        <NoteItemMarkdown content={highlight || content} editable={editable} />
      );
    }
    if (isShortConversation) {
      return <ShortConversationList conversation={conversation} />;
    }
    return <ConversationList conversation={conversation} initials={initials} />;
  }, [
    content,
    conversation,
    editable,
    highlight,
    initials,
    isShortConversation,
  ]);

  return (
    <Box
      data-testid="NoteItemContent"
      sx={{
        maxHeight: isChatConversation ? (isShortConversation ? 40 : 400) : 200,
        overflowY: isShortConversation ? 'initial' : 'auto',
      }}
    >
      <Box {...props}>{contentComponent}</Box>
    </Box>
  );
};
